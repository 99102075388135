import React from 'react';

import styles from './InfoBox.module.css';

const infoBox = props => {
  let content;

  switch (props.consultationType) {
    case 'research':
      if (props.english) {
        content = (
          <div className={styles.Container}>
          <h3>Not fitting your schedule?</h3>
          <p>Just send us an e-mail to arrange a different meeting time.</p>
          <ul>
            <li><a href="mailto:information@ub.uni-frankfurt.de">Zentralbibliothek (allgemeine Anfragen)</a></li>
            <li><a href="mailto:bzg-info@ub.uni-frankfurt.de">Bibliothekszentrum Geisteswissenschaften</a></li>
            <li><a href="mailto:bsp@ub.uni-frankfurt.de">Bibliothek Sozialwissenschaften und Psychologie</a></li>
            <li><a href="mailto:bruw-info@ub.uni-frankfurt.de">Bibliothek für Recht und Wirtschaft</a></li>
            <li><a href="mailto:bnat-teams@ub.uni-frankfurt.de">Bibliothek für Naturwissenschaften</a></li>
          </ul>
          </div>
        );
      } else {
        content = (
          <div className={styles.Container}>
          <h3>Kein passender Termin dabei?</h3>
          <p>Sie können gerne per E-Mail einen Alternativtermin mit uns vereinbaren.</p>
          <ul>
            <li><a href="mailto:information@ub.uni-frankfurt.de">Zentralbibliothek (allgemeine Anfragen)</a></li>
            <li><a href="mailto:bzg-info@ub.uni-frankfurt.de">Bibliothekszentrum Geisteswissenschaften</a></li>
            <li><a href="mailto:bsp@ub.uni-frankfurt.de">Bibliothek Sozialwissenschaften und Psychologie</a></li>
            <li><a href="mailto:bruw-info@ub.uni-frankfurt.de">Bibliothek für Recht und Wirtschaft</a></li>
            <li><a href="mailto:bnat-teams@ub.uni-frankfurt.de">Bibliothek für Naturwissenschaften</a></li>
          </ul>
          </div>
        );
      }
      break;
    default:
      if (props.english) {
        content = (
          <div className={styles.Container}>
            <h3>Booking period</h3>
            <p>
              <strong>You can only book appointments starting four days from now</strong>. This ensures
              that we have enough time to properly prepare for your consultation, e.g. by reading your paper.
            </p>
          </div>
        );
      } else {
        content = (
          <div className={styles.Container}>
            <h3>Information zum Anmeldezeitraum</h3>
            <p>
              Damit wir genug Zeit zur Vorbereitung deiner Beratung haben, kannst du <strong>Termine frühestens
              vier Tage im Voraus</strong> buchen.
            </p>
          </div>
        );
      }
      break;
  }

  return content;
}

export default infoBox;
