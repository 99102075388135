import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-dates/axios-dates';

import PTStatDisplayer from '../../../components/Admin/PTAdmin/PTStatDisplayer';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Button from '../../../components/UI/Button/Button';

class PTAdmin extends Component {
  state = {
    loading: true,
    stats: {},
    error: null
  }

  loadStats = () => {
    const url = '/pt/XXX.php';
    const auth = {
      jwt: this.props.token
    }
    //gets individual stats for each tutor
    axios.post(url, auth)
      .then(res => {
        if (res.data.success === 1) {
          const newStats = res.data.stats;
          this.setState({stats: newStats, loading: false});
        } else {
          this.setState({loading: false, error: res.data.msg});
        }
      })
      .catch(err => {
        this.setState({loading: false, error: err.message});
      })
  }

  componentDidMount () {
    //this.loadAppointments();
    this.setState({loading: false});
  }

  render () {
    let toDisplay = null;

    return (
      <>
        <h1 style={{textAlign: 'center'}}>PT-Verwaltung</h1>
        {this.state.loading ?
          <Spinner /> :
          <PTStatDisplayer stats={this.state.stats}/>
        }
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loggedIn: state.pt.loggedIn,
    ptId: state.pt.ptId,
    token: state.pt.token
  };
};

export default connect(mapStateToProps)(PTAdmin);
