import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from '../../../axios-dates/axios-dates';

import AuxComp from '../../../hoc/AuxComp/AuxComp';
import Modal from '../../../components/UI/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';
import ISZForm from '../../../components/RS/Submit/Form/ISZForm';
import SubmitFormButtons from '../../../components/RS/Submit/SubmitFormButtons/SubmitFormButtons';


class ISZSubmitter extends Component {
  state = {
    showModal: false,
    error: null,
    rsInfo: {
      firstName: '',
      lastName: '',
      email: '',
      repeatEmail: '',
      semester: '1-2',
      abschluss: 'bachelor',
      fachbereich: 1,
      fach: '',
      deutschAls: 'Erstsprache',
      gender: 'Männlich',
      erstStudierend: 1,
      genre: 'Hausarbeit',
      format: 'Beratung per Videokonferenz',
      comment: '',
      terminReasons: {
        themaEntwickeln: false,
        feedback: false,
        anfangen: false,
        struktur: false,
        stil: false,
        literatur: false,
        daf: false,
        dozEmpfehlung: false,
        kritik: false,
        anforderungenUniText: false,
        sonstige: false
      },
      reachedBy: {
        flyer: false,
        dozierende: false,
        webseite: false,
        ov: false,
        kommilitonen: false,
        schreibcafe: false,
        sprachkurs: false,
        lndah: false,
        austauschrunde: false
      }
    },
    textfile: null,
    invalidForm: false
  }

  inputHandler = (event) => {

    const currentInfo = {...this.state.rsInfo};

    switch (event.target.type) {
      case 'text':
      case 'email':
      case 'select-one':
        currentInfo[event.target.id] = event.target.value;
        this.setState({
          rsInfo: currentInfo
        });
        break;

      case 'checkbox':
        const checkboxesName = event.target.parentElement.parentElement.id;
        const clickedBox = event.target.value;
        const updatedCheckboxes = currentInfo[checkboxesName];
        updatedCheckboxes[clickedBox] = !updatedCheckboxes[clickedBox];
        currentInfo[checkboxesName] = updatedCheckboxes;
        this.setState({
          rsInfo: currentInfo
        });
        break;

      case 'file':
        console.log(event.target.files[0]);
        this.setState({textfile: event.target.files[0]});
        break;

      default:
      currentInfo[event.target.id] = event.target.value;
      this.setState({
        rsInfo: currentInfo
      });
    }
  };

  submitHandler = () => {
    const currentInfo = {...this.state.rsInfo};
    if (currentInfo.firstName < 1 || currentInfo.lastName < 1 || !(currentInfo.email.includes('@')) || !(currentInfo.email === currentInfo.repeatEmail)) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.setState({invalidForm: true});
      return null;
    }
    else {
      this.setState({showModal: true});

      const formData = new FormData();

      const payload = {
        termsAccepted: this.props.termsAccepted,
        rsInfo: this.state.rsInfo
      };

      formData.append("payload", JSON.stringify(payload));

      if (this.state.textfile) {
        const filesize = ((this.state.textfile.size/1024)/1024).toFixed(4); // MB
        if (filesize <= 10) {
          formData.append(
            "textfile",
            this.state.textfile,
            this.state.textfile.name
          );
        } else {
          this.setState({showModal: true, error: "Ausgewählte Textdatei ist größer als 10 MB."});
          return null;
        }
      }

      axios.post('/rs/make-isz-appointment.php', formData)
      .then(res => {
        console.log(res);
        if (res.data.success === 1) {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
          this.props.history.push({pathname: '/rs/success/'});
        } else {
          this.setState({showModal: true, error: res.data.msg});
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({showModal: true, error: err.message});
      })
    }
  }

  goBackHandler = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.props.history.push({pathname: '/rs/select-type'})
  }

  backdropClickHandler = () => {
    this.setState({showModal: false, error: null});
  }

  render () {
    const currentRSInfo = {...this.state.rsInfo};

    let modalContent = (
      <Spinner />
    );

    if (this.state.error) {
      modalContent = (
        <div>
        <p>Beim Reservieren des Termins ist ein Fehler aufgetreten. Falls das Problem bestehen bleibt, kontaktiere uns bitte unter <a href="mailto:schreibzentrum@dlist.uni-frankfurt.de">schreibzentrum@dlist.uni-frankfurt.de</a>.</p>
        <p>Fehlermeldung: {this.state.error}</p>
        </div>
      );
    }

    return (
      <AuxComp>
        <Modal visible={this.state.showModal} onBackdropClick={this.backdropClickHandler}>
          {modalContent}
        </Modal>
        <ISZForm
          rsInfo={this.state.rsInfo}
          inputHandler={this.inputHandler}
          invalidForm={this.state.invalidForm}
          english={this.props.english} />
        <SubmitFormButtons
          available={currentRSInfo.firstName.length >= 1 && currentRSInfo.lastName.length >= 1 && currentRSInfo.email.includes('@')}
          goBackHandler={this.goBackHandler}
          submitHandler={this.submitHandler}
          english={this.props.english} />
      </AuxComp>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    consultationType: state.rs.consultationType,
    termsAccepted: state.rs.termsAccepted,
    english: state.rs.english
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ISZSubmitter));
