import React from 'react';

import styles from './TypeButton.module.css';
import ubLogo from '../../../../assets/ublogo_negativ_transparent.png';
import szLogo from '../../../../assets/Logo_SZ_dark_transparent.png';
import szarLogo from '../../../../assets/logo_szar.png';
import iszLogo from '../../../../assets/LogoISZsmall.png';
import methodenLogo from '../../../../assets/logo_methodenzentrum.png';

const typeButton = props => {
  let logo;
  let logoAltText;
  switch (props.logo) {
    case 'sz':
      logo = szLogo;
      logoAltText = 'Writing Center logo';
      break;
    case 'szar':
      logo = szarLogo;
      logoAltText = 'STEM Writing Center logo';
      break;
    case 'ub':
      logo = ubLogo;
      logoAltText = 'University library logo';
      break;
    case 'isz':
      logo = iszLogo;
      logoAltText = 'International Student Center logo';
      break;
    case 'methodenzentrum':
      logo = methodenLogo;
      logoAltText = 'Method Center logo';
      break;
    default:
      logo = szLogo;
      logoAltText = 'Writing Center logo';
      break;
  }
  return (
    <button className={styles.Button} onClick={props.buttonHandler}>
      <p>{props.children}</p>
      <img src={logo} alt={logoAltText}/>
    </button>
  );
}

export default typeButton;
