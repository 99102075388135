import React from 'react';

import guLogo from '../../assets/gu-logo.png';
import szLogo from '../../assets/Logo_SZ_dark_transparent.png';
import styles from './Logo.module.css';

const logo = (props) => (
  <div className={styles.Logo}>
    <img src={guLogo} alt="App Logo" />
    <img src={szLogo} alt="App Logo" style={{height: '80%'}} />
  </div>
);

export default logo;
